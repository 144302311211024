<template>
  <div v-if="productData">
    <div class="hospital">
      <img src="../assets/location-icon.png" alt="" />
      <p>{{ cityName }}</p>
    </div>
    <div class="banner">
      <img :src="productData.detailImg" alt="" />
    </div>
    <div class="detail">
      <p class="name">{{ productData.mainName }}</p>
    </div>
    <div class="line"></div>
    <div class="typeList">
      <p
        v-for="(item, index) in productData.plans"
        :key="index"
        :class="activeType == index ? 'active' : 'no-active'"
        @click="changeType(index, item)"
      >
        {{ item.securityPlanName }}
      </p>
    </div>
    <div class="ensureDetail">
      <div class="inviolable-rights-title">
        <div class="left">保障责任</div>
        <div class="right" @click="rightsAndInterests">
          <p>查看详情</p>
          <img src="../assets/right-icon.png" alt="" />
        </div>
      </div>
      <div class="inviolable-rights-msg">
        <div class="item" v-for="(item, index) in plansData" :key="index">
          <p class="name">{{ item.benefitName }}</p>
          <p class="price">{{ item.insuredAmountShow }}</p>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="claim-process">
      <div class="tit">理赔流程</div>
      <div class="step">
        <div class="name">
          <img src="../assets/step1-icon.png" alt="" />
          <p>报案申请</p>
        </div>
        <div class="msg">
          <div class="left">
            <p></p>
          </div>
          <div class="right">
            拨打中国人民保险客服热线：95518或人保健康北京分公司疫苗专属热线010-80882173。
          </div>
        </div>
      </div>
      <div class="step">
        <div class="name">
          <img src="../assets/step2-icon.png" alt="" />
          <p>提交资料</p>
        </div>
        <div class="msg">
          <div class="left">
            <p></p>
          </div>
          <div class="right">按指引准备理赔资料。</div>
        </div>
      </div>
      <div class="step">
        <div class="name">
          <img src="../assets/step3-icon.png" alt="" />
          <p>理赔金给付</p>
        </div>
        <div class="msg">
          <div class="left"></div>
          <div class="right">
            有关证明和资料完整后，保险公司依据保险条款，理算保险金。进入保险公司理赔系统，3个工作日内给付保险金。
          </div>
        </div>
      </div>
    </div>
    <div class="distribution">
      业务员：张瑜；业务员工号：2111132566 <br />
      申请人领取保险金后，保险公司还将委派专人根据其情况进行就医、就学、就业、伤残保障和救济等相应政策的指导，使其尽可能得到政策帮扶。
    </div>
    <div class="footer">
      <div class="price">
        <p>价格：¥</p>
        <p class="num">{{ price }}</p>
        <p>元/起</p>
      </div>
      <div class="goToInsuranceNotice" @click="goToInsuranceNotice">
        立即投保
      </div>
    </div>
    <!-- 回溯须知 -->
    <van-popup
      class="firstBottomPopup"
      v-model="recallPopup"
      :close-on-click-overlay="false"
      position="bottom"
    >
      <div class="notify">
        <div class="title">投保说明</div>
        <div class="msg">
          <p>
            即将进入投保流程，请仔细阅读<span @click="customerNotification"
              >客户告知书</span
            >等，为了保障您的权益，您的投保轨迹将被记录井加密保存。
          </p>
        </div>
        <div class="tips">
          <p>
            本产品由中国人民健康保险股份有限公司承保，由乐荐保保险代理有限公司提供销售和协助理赔等保险服务。
          </p>
        </div>
        <div class="btn" @click="okBtn">我知道了</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { productDetail, productBenefitDetail } from "../utils/api";
export default {
  components: {},
  data() {
    return {
      activeType: 0,
      productData: null,
      plansId: "",
      plansData: [],
      recallPopup: true,
      isFirstEnter: false,
      cityName: null,
      price: null, //产品价格
    };
  },
  created() {
    //判断是否需要重新获取回溯id
    this.recallPopup = !sessionStorage.getItem("know");
  },
  beforeRouteEnter(to, from, next) {
    if (from.name == "rightsAndInterests") {
      to.meta.isBack = true;
    }
    next();
  },
  activated() {
    this.cityName = sessionStorage.getItem("cityName");
    this.productDetail(sessionStorage.getItem("productId"));
    this.recallPopup =
      !this.$route.meta.isBack || this.isFirstEnter
        ? !sessionStorage.getItem("know")
        : false;
    this.isFirstEnter = false;
    this.$route.meta.isBack = false;
  },
  methods: {
    //客户告知书
    customerNotification() {
      this.$router.push({
        path: "/pdf",
        query: {
          type: "native",
        },
      });
    },
    //跳转产品资料
    material() {
      this.$router.push({
        path: "/material",
        query: {
          id: this.$route.query.id,
        },
      });
    },
    //阅读并投保
    next() {
      this.$router.push({
        path: "/insurePackage",
        query: {
          id: this.$route.query.id,
        },
      });
    },
    //立即投保
    goToInsuranceNotice() {
      //跳转投保须知
      this.$router.push({
        path: "/insuranceNotice",
        query: {
          channel: "technologicalProcess",
        },
      });
    },
    //权益-查看详情
    rightsAndInterests() {
      const price = this.activeType === 0 ? "150万" : "300万";
      this.$router.push({
        path: "/rightsAndInterests",
        query: {
          plansId: this.plansId,
          price,
        },
      });
    },
    //我知道了
    okBtn() {
      this.recallPopup = false;
      sessionStorage.setItem("know", true); //存储用户是否知晓该提示
    },

    //权益数据
    productBenefitDetail(plansId) {
      productBenefitDetail({
        plansId,
      }).then(({ success, result }) => {
        if (success) {
          this.plansData = [
            ...result,
            {
              benefitName: "保险期间",
              insuredAmountShow: "1-6年",
            },
          ];
        }
      });
    },
    //产品详情
    productDetail(productId) {
      productDetail({
        productId,
      }).then(({ success, result }) => {
        if (success) {
          this.productData = result;
          const activePlan = result?.plans[this.activeType];
          if (activePlan) {
            this.productBenefitDetail(activePlan.id);
            this.plansId = activePlan.id;
            this.price = activePlan.planPremium;
          }
        }
      });
    },
    //切换type
    changeType(index, item) {
      this.productBenefitDetail(item.id);
      this.plansId = item.id;
      this.activeType = index;
      this.price = item.planPremium;
    },
  },
};
</script>

<style lang="scss" scoped>
.hospital {
  width: 100%;
  background: #fff7f4;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  img {
    width: 12px;
    margin-right: 7px;
  }
  p {
    font-size: 14px;
    color: $primary-color;
    line-height: 21px;
  }
}
.banner {
  width: 100%;
  background: #f2f2f2;
  img {
    width: 100%;
  }
}
.detail {
  padding: 0 10px;
  .name {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 22px;
    margin-top: 13px;
  }
  .introduction {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    text-align: justify;
    margin-top: 5px;
  }
  .from {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    margin-top: 12px;
  }
  .distribution-company {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    margin-top: 4px;
  }
}
.line {
  width: 100%;
  height: 10px;
  background: #f7f7f7;
  margin-top: 20px;
}
.typeList {
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 0 10px;
  p {
    height: 40px;
    border-radius: 24px;
    margin-right: 19px;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
  }
  .active {
    background: linear-gradient(90deg, #ffa466 0%, $primary-color 100%);
    color: #fff;
  }
  .no-active {
    border: 1px solid $primary-color;
    color: $primary-color;
  }
}
.commission {
  padding: 0 15px;
  margin-top: 16px;
  .msg {
    width: 100%;
    background: url("../assets/commission-bg.png") no-repeat;
    background-size: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    .left {
      display: flex;
      align-items: center;
      p {
        font-size: 14px;
        font-weight: bold;
        color: #e06c49;
        line-height: 20px;
      }
      img {
        width: 14px;
        margin-left: 8px;
      }
    }
    .share {
      img {
        width: 54px;
      }
    }
  }
}
.ensureDetail {
  padding: 0 10px;
  .inviolable-rights-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    .left {
      font-size: 15px;
      font-weight: bold;
      color: #333333;
      line-height: 21px;
    }
    .right {
      display: flex;
      align-items: center;
      p {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
      }
      img {
        width: 12px;
        margin-left: 3px;
      }
    }
  }
  .inviolable-rights-msg {
    border-radius: 4px;
    margin-top: 8px;

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      p {
        font-size: 15px;
        font-weight: 400;
        color: #333;
        line-height: 21px;
      }
    }
  }
}
.guaranteed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  margin-top: 12px;
  p {
    font-size: 15px;
    font-weight: 400;
    color: #333333;
    line-height: 21px;
  }
}
.msgImg {
  width: 100%;
  padding: 0 10px;
  /deep/ img {
    width: 100% !important;
  }
}
.claim-process {
  padding: 20px 10px 10px;
  .tit {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 22px;
  }
  .step {
    margin-top: 17px;
    .name {
      display: flex;
      align-items: center;
      img {
        width: 15px;
      }
      p {
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
        margin-left: 6px;
      }
    }
    .msg {
      margin-top: 7px;
      display: flex;
      .left {
        width: 15px;
        display: flex;
        justify-content: center;
        p {
          width: 1px;
          border-left: 1px dashed #ffbfac;
        }
      }
      .right {
        flex: 1;
        width: 100%;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
        text-align: justify;
        margin-left: 6px;
        margin-bottom: 15px;
      }
    }
  }
}
.distribution {
  padding: 12px 10px 80px;
  background: $bg7;
  font-size: 12px;
  font-weight: 400;
  color: #9c9c9c;
  line-height: 17px;
}
.notify {
  padding: 28px 25px 14px;
  .title {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 17px;
  }
  .msg {
    margin-top: 12px;
    p {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      text-align: justify;
    }
    span {
      color: #569cff;
    }
  }
  .tips {
    margin-top: 20px;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 17px;
    text-align: justify;
  }
  .btn {
    width: 307px;
    height: 45px;
    margin: 0 auto;
    background: $primary-color;
    border-radius: 26px;
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
  }
}
.inform {
  height: 100%;
  padding: 20px;
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  .title {
    position: relative;
    background: #fff;

    p {
      width: 100%;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
      text-align: center;
    }
    img {
      position: absolute;
      top: 4px;
      right: 0;
      width: 14px;
    }
  }
  .detail {
    margin-top: 20px;
    font-size: 15px;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    text-align: justify;
    padding: 0;
    overflow-y: auto;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 159px;
      height: 45px;
      border-radius: 23px;
      font-size: 15px;
      font-weight: 500;
      line-height: 21px;
    }
    p:first-child {
      border: 1px solid $primary-color;
      color: $primary-color;
    }
    p:last-child {
      background: $primary-color;
      border: 1px solid $primary-color;
      color: #fff;
    }
  }
  .next {
    p {
      height: 45px;
      border-radius: 23px;
      font-size: 15px;
      font-weight: 500;
      line-height: 21px;
      width: 234px;
      background: $primary-color;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      margin-top: 20px;
    }
  }
}
.health {
  width: 320px;
  height: 160px;
  background: #ffffff;
  border-radius: 8px;
  p {
    text-align: center;
  }
  .title {
    font-size: 17px;
    font-weight: 500;
    color: #333;
    line-height: 24px;
    margin-top: 32px;
    margin-bottom: 24px;
  }
  .tip {
    font-size: 17px;
    font-weight: 400;
    color: #999;
    line-height: 24px;
  }
  .btn {
    margin-top: 34px;
    font-size: 17px;
    font-weight: 500;
    color: #576b95;
    line-height: 24px;
    letter-spacing: 1px;
    padding: 15px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.footer {
  border: solid 1px #eaeaea;
  padding: 0 10px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99;
  background: #fff;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .price {
    display: flex;
    align-items: flex-end;
    p {
      font-size: 15px;
      font-weight: 500;
      color: $primary-color;
      line-height: 15px;
    }
    .num {
      font-size: 20px;
      line-height: 19px;
    }
  }
  .goToInsuranceNotice {
    width: 116px;
    height: 45px;
    background: linear-gradient(90deg, #ffa466 0%, $primary-color 100%);
    border-radius: 24px;
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-right: 12px;
  }
}
/deep/ .van-tab--active {
  color: $primary-color;
}
/deep/ .van-tabs__line {
  background-color: $primary-color;
  height: 4px;
  width: 16px;
  border-radius: 2px;
  bottom: 20px;
}
.bottomPopup {
  /deep/ .van-popup {
    border-radius: 20px 20px 0 0;
    height: 70%;
  }
}
/deep/ .centerPopup {
  border-radius: 8px;
}
/deep/ .firstBottomPopup {
  padding-bottom: 20px;
  border-radius: 20px 20px 0 0;
  height: auto;
}
</style>